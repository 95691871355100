import React, { useState } from "react";
import { connect } from "react-redux";
import Modal from 'react-modal';
import ResourcesCard from '../components/ResourcesCard';

Modal.setAppElement('#root')

const Home = props => {
    const [showCalendars, setShowCalendars] = useState(false)
    const resources = require("../resources.json")

    const toggleModal = () => {
        setShowCalendars(!showCalendars)
    }
    return (
        <div className="dashboard container-xl">
            <div className="row">
                <Modal
                    isOpen={showCalendars}
                    onRequestClose={toggleModal}
                    contentLabel="Calendars"
                >
                    <button className="exit-modal" onClick={toggleModal}>x</button>
                    <div className="calendar-modal">
                        <iframe title="SDA/Corsi" className="calendar-modal-iframe" src="https://calendar.google.com/calendar/embed?src=skillbill.it_s0n80akve07mvi22ago2ivj6ug%40group.calendar.google.com&ctz=Europe%2FRome" frameBorder="0" scrolling="no"></iframe>
                        <iframe title="Presenze/Assenze Ufficio" className="calendar-modal-iframe" src="https://calendar.google.com/calendar/embed?src=skillbill.it_31a874r618oa9oi9lgln9a6sr4%40group.calendar.google.com&ctz=Europe%2FRome" frameBorder="0" scrolling="no"></iframe>
                        <iframe title="Compleanni" className="calendar-modal-iframe" src="https://calendar.google.com/calendar/embed?src=skillbill.it_u8sosebn6cienoilvk20tbpui4%40group.calendar.google.com&ctz=Europe%2FRome" frameBorder="0" scrolling="no"></iframe>
                        <iframe title="Assenze e Ferie" className="calendar-modal-iframe" src="https://calendar.google.com/calendar/embed?src=skillbill.it_nbuohn3nuh60dledpdv91et4pc%40group.calendar.google.com&ctz=Europe%2FRome" frameBorder="0" scrolling="no"></iframe>
                    </div>
                </Modal>
                <h2>Resources</h2>
                <div className="dashboard-grid">
                    {resources.map(r => {
                        return (
                            <ResourcesCard key={r.title} openCalendarModal={toggleModal} {...r}/>
                        )}
                    )}
                </div>
            </div>
        </div>
    );
}

export default connect((state) => {
    return {
        auth: state.firebase.auth
    };
})(Home);