import { SHOW_ERROR, STATE_READY, STATE_LOADING, STATE_ERROR, API_REQUEST } from './constants'
import { toast } from 'react-toastify'


const throttled = {};

const THROTTELING_TIME = 1000

export const errorHandler = () => next => action => {
  
  if (action.type !== SHOW_ERROR)
    return next(action)
  
  const message = action.meta && action.meta.message ? action.meta.message : "Something went wrong"

  if (!throttled[message]) {
    toast.error(message, { autoClose: 2000 })
    throttled[message] = true;

    setTimeout(
      () => throttled[message] = false,
      THROTTELING_TIME
    );
  }
}

const dispatchError = (error, message) => ({
  type: SHOW_ERROR,
  payload: error,
  meta: {
    message
  }
})


export const api = ({dispatch}) => next => action => {
  if (action.type !== API_REQUEST) {
    return next(action);
  }
  
  const { request, success, label, currentState } = action.payload;
  
  if ([STATE_READY, STATE_LOADING].includes(currentState))
    return
  
  dispatch({
    type: label,
    payload: STATE_LOADING
  });

  request().then(data => {
      success(data)
      dispatch({
        type: label,
        payload: STATE_READY
      });
    })
    .catch(error => {
      dispatchError(error)
      dispatch({
        type: label,
        payload: STATE_ERROR
      });
    })
}