export const LOGIN_ERROR = "LOGIN_ERROR"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS"
export const STORE_PROFILE = "STORE_PROFILE"
export const STORE_PROFILE_ERROR = "STORE_PROFILE_ERROR"
export const SET_ROLES = "SET_ROLES"
export const SET_COMPANIES = "SET_COMPANIES"
export const SET_PROJECTS = "SET_PROJECTS"
export const SET_TASKS = "SET_TASKS"
export const SET_ACCOUNTS = "SET_ACCOUNTS"
export const SET_ACCOUNTS_STATE = "SET_ACCOUNTS_STATE"
export const STATE_LOADING = "STATE_LOADING"
export const STATE_ERROR = "STATE_ERROR"
export const STATE_NOT_READY = "STATE_NOT_READY"
export const STATE_READY = "STATE_READY"
export const SHOW_ERROR = "SHOW_ERROR"
export const API_REQUEST = "API_REQUEST"
export const SET_USER_ROLES_STATUS = "SET_USER_ROLES_STATUS"