import { applicationConfig } from './App'
import { getFirebase } from "react-redux-firebase"

function httpRequest(url, method, data) {
   return fetch(url, {
        method,
        mode: 'cors',
        cache: "no-cache",
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: method !== 'GET' ? JSON.stringify(data) : null,
        queryParams: method === "GET" ? data : null
    })
}

export const deleteRequest = async (path, data={}) => {
    data.token = await getFirebase().auth().currentUser.getIdToken()
    const url = applicationConfig.config.api + path
    return httpRequest(url, "DELETE", data)
}

export const postRequest = async (path, data = {}) => {
    data.token = await getFirebase().auth().currentUser.getIdToken()
    const url = applicationConfig.config.api + path
    return httpRequest(url, "POST", data)
}

export const putData = async (path, data = {}) => {
    data.token = await getFirebase().auth().currentUser.getIdToken()
    const url = applicationConfig.config.api + path
    return httpRequest(url, "PUT", data)
}

export const getData = async (path, queryParams={}) => {
    queryParams.token = await getFirebase().auth().currentUser.getIdToken()
    let url = new URL(applicationConfig.config.api + path)
    url.search = new URLSearchParams(queryParams)
    return httpRequest(url, "GET", queryParams)
}

export const getJsonData = async (path, queryParams={}) => {
    return await getData(path, queryParams).then(response => response.json())
}

export const postJsonData = async (path, data={}) => {
    return await postRequest(path, data).then(response => response.json())
}