import React, { Suspense } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import Loading from '../elements/Loading'

const RoleRoute = ({ component: Component, userRoles, roleAllowed, auth, ...rest }) => (
  auth && auth.uid && userRoles.includes(roleAllowed) ? (
    <Route {...rest} render={(routeProps) => (
      <Suspense fallback={<Loading />}><Component {...routeProps} /></Suspense>
    )} />)
    : <Redirect to='/login' />
)

export default connect((state) => {
  return {
    auth: state.firebase.auth,
    userRoles: state.auth.roles
  };
})(RoleRoute);