import { SIGNOUT_SUCCESS, LOGIN_SUCCESS, LOGIN_ERROR, SET_ROLES, SET_USER_ROLES_STATUS, API_REQUEST } from "../constants"


export const signIn = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({ hd: 'skillbill.it' });

        firebase.auth().signInWithPopup(provider).then(() => {
            dispatch({ type: LOGIN_SUCCESS });
            
        }).catch((error) => {
            dispatch({ type: LOGIN_ERROR, error });
        });
    }
}

export const signOut = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();
        firebase.auth().signOut().then(() => {
            dispatch({ type: SIGNOUT_SUCCESS });
        }).catch((error) => {
            dispatch({ type: LOGIN_ERROR, error });
        });
    }
}

export const fetchUserRoles = () => {
    return (dispatch, getState, {getJsonData}) => {
        dispatch({
        type: API_REQUEST,
        payload: {
            request: () => getJsonData("/userRoles"),
            currentState: getState().auth.userRolesStatus,
            label: SET_USER_ROLES_STATUS,
            success: ({roles}) => dispatch({ type: SET_ROLES, payload: roles })
        }
        })
    }
}