import Confit from 'confit-client';
import React from "react";
import ReactDOM from "react-dom";
import App, { applicationConfig } from './App';
import * as serviceWorker from "./serviceWorker";
import './skb-style.css'
import "./index.css"
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./store/reducers/rootReducer";
import {errorHandler, api} from './store/middlewares'
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { reactReduxFirebase, getFirebase } from "react-redux-firebase";
import { putData, getData, getJsonData, postJsonData } from "./api"
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

const config = Confit.create({
  repoId: 'a52b2915-070e-42c2-b439-27abdc1f72ed'
});

let loadConfiguration = (arg) => {
  return config.load.call(config, arg);
}

if ('development' === process.env.NODE_ENV) {
  loadConfiguration = () => new Promise(function (resolve, reject) {
    console.log('local development configuration');
    resolve({
      firebase: {
        apiKey: "AIzaSyANBUyZNSJZR8vJTsAfovCKrGp7tTGULko",
        authDomain: "skillbill-intranet-test.firebaseapp.com",
        databaseURL: "https://skillbill-intranet-test.firebaseio.com",
        projectId: "skillbill-intranet-test",
        storageBucket: "skillbill-intranet-test.appspot.com",
        messagingSenderId: "928666165119",
        appId: "1:928666165119:web:43011500ed3b363f61844a",
        measurementId: "G-SMXK41MS75"
      },
      api: "http://localhost:3001",
      oldIntranetEntrypoint : "http://localhost:8080/intranet/timesheet/index.cb?sso="
    });
  })
}

loadConfiguration({
  alias: `${window.location.host}-FE`
}).then(loadedConf => {

  applicationConfig.firebase = firebase.initializeApp(loadedConf['firebase']);
  firebase.analytics();
  applicationConfig.config = loadedConf;

  let devTools = a => a;
  if ('development' === process.env.NODE_ENV) {
    const windowDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
    devTools = windowDevTools ? windowDevTools : devTools;
  }

  const store = createStore(rootReducer,
    compose(
      applyMiddleware(
        thunk.withExtraArgument({ getFirebase, putData, getData, getJsonData, postJsonData }),
        errorHandler,
        api
      ),
      reactReduxFirebase(applicationConfig.firebase, { attachAuthIsReady: true }),
      devTools
    )
  );

  store.firebaseAuthIsReady.then(() => {
    ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

    serviceWorker.unregister();

  });

});



