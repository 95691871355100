import React from 'react'
import { connect } from "react-redux";
import { signIn } from "../store/actions/authActions";
import { Redirect } from "react-router-dom";

const Login = ({signIn, auth}) => (
  <div className="container login">
    {auth.email ? <Redirect to="/" /> : <button onClick={signIn}>Login</button>}
  </div>
)

export default connect(
  (state) => {
      return {
          auth: state.firebase.auth
      }
  },
  (dispatch) => {
      return {
          signIn: () => dispatch(signIn())
      }
  }
)(Login);
