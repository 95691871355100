import { SET_TASKS, SET_COMPANIES, SET_PROJECTS, SET_ACCOUNTS, SET_ACCOUNTS_STATE, STATE_NOT_READY } from '../constants'

const initialState = {
  companies: undefined,
  projects: undefined,
  tasks: undefined,
  accounts: undefined,
  accountsState: STATE_NOT_READY
}


export default (state = initialState, {type, payload}) => {
  switch (type) {
    case SET_COMPANIES:
      return {
        ...state,
        companies: payload
      }
    case SET_PROJECTS:
      return {
        ...state,
        projects: payload
      }
    case SET_TASKS:
      return {
        ...state,
        tasks: payload
      }
    case SET_ACCOUNTS: 
      return {
        ...state,
        accounts: payload
      }
    case SET_ACCOUNTS_STATE:
      return {
        ...state,
        accountsState: payload
      }
    default:
      return state;
  }

}
