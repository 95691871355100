import { Link, NavLink } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { DOCUMENTS_ROLE, TIMESHEET_ROLE, PROFILE_ROLE } from '../constants'
import { putData } from "../api"
import { signOut } from "../store/actions/authActions";
import { connect } from "react-redux";
import { applicationConfig } from "../App";
import { toast } from 'react-toastify'
import "./styles/NavBar.css"
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { fetchUserRoles } from "../store/actions/authActions";

const Navbar = (props) =>  {
  const [showSettings, setShowSettings] = useState(false);
  const { auth, userRoles, fetchUserInfo } = props;
  
  useEffect(() => {
    fetchUserInfo()
  }, [fetchUserInfo, auth])


  let links;
  let imageProfile;

  const redirectOldTimesheet = () => {
    putData("/prepareSso", {
      email: auth.email
    })
    .then((response) => {
      response.json().then((json) => {
        window.open(`${applicationConfig.config.oldIntranetEntrypoint}${json.id}`, '_blank')
      });

    }).catch((error) => {
      toast.error("Something went wrong", { autoClose: 2000 })
      console.error("error", error);
    });
  }

  if (auth.uid) {
    links = (
      <>
        {userRoles.includes(PROFILE_ROLE) ? <li><NavLink to="/teammate" >Teammate Profile</NavLink></li> : null}
        {userRoles.includes(TIMESHEET_ROLE) ? <li><NavLink to="/timesheet" >Timesheet</NavLink></li> : null}
        {userRoles.includes(DOCUMENTS_ROLE) ? <li><NavLink to="/documents" >Documents</NavLink></li> : null}
      </>
    )

    const togglePopup = e => {
      e.preventDefault()
      setShowSettings(!showSettings)
    }

    imageProfile = (
      <div className="right" onClick={togglePopup}>
        <div className="popup" style={{display: showSettings ? 'flex' : 'none'}}>
          <div className="popup-background" onClick={togglePopup} />
          {/* eslint-disable-next-line */}
          <a href="#" onClick={props.signOut} >Log Out</a>
        </div>
        <MoreVertIcon />
        <img src={auth.photoURL} alt="profile" />
      </div>
    )
  }

  return (
    <header>
      <h1>
        <Link to="/" className="brand-logo">
          Intranet
        </Link>
      </h1>
      <nav>
        <ul>
          {links}
        </ul>
      </nav>
      {imageProfile}
    </header>
  );
}

export default connect(
  (state) => {
    return {
      auth: state.firebase.auth,
      userRoles: state.auth.roles
    }
  },
  (dispatch) => {
    return {
      signOut: () => dispatch(signOut()),
      fetchUserInfo: () => dispatch(fetchUserRoles())
    }
  }
)(Navbar);
