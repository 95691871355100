import { SIGNOUT_SUCCESS, LOGIN_SUCCESS, LOGIN_ERROR, SET_ROLES, STATE_NOT_READY, SET_USER_ROLES_STATUS } from "../constants"
import { PROFILE_ALL_ROLE, TIMESHEET_ADMIN_ROLE} from '../../constants'

const initialState = {
    authError: null,
    roles: [],
    haveAllProfileRole: false,
    haveTimesheetAdminRole: false,
    userRolesStatus: STATE_NOT_READY
};

const authReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case SET_ROLES: 
            return {
                ...state,
                roles: payload,
                haveAllProfileRole: payload.includes(PROFILE_ALL_ROLE),
                haveTimesheetAdminRole: payload.includes(TIMESHEET_ADMIN_ROLE)
            }
        case LOGIN_ERROR:
            console.log("Login error");
            return {
                ...state,
                authError: "Login Failed"
            };
        case LOGIN_SUCCESS:
            console.log("Login success");
            return {
                ...state,
                authError: null
            }
        case SIGNOUT_SUCCESS:
            console.log("Signout success");
            return state;
        case SET_USER_ROLES_STATUS:
            return {
                ...state,
                userRolesStatus: payload
            }
        default:
            return state;
    }
}

export default authReducer;