import authReducer from "./authReducer";
import profileReducer from "./profileReducer";
import timesheetReducer from "./timesheetReducer";
import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";

export default combineReducers( {
    auth : authReducer, 
    profile : profileReducer,
    timesheet: timesheetReducer,
    firebase : firebaseReducer
});