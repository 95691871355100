import React from "react";

export default ({noContainer}) => {
    if (noContainer) 
        return (
            <div className="spinner" />
        );
    else   
        return (
            <div className="container">
                <div className="spinner" />
            </div>
        );
}