import React, { Suspense } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import Loading from '../elements/Loading'

const PrivateRoute = ({ component: Component, auth,...rest }) => (
  <Route {...rest} render={(routeProps) => (
    auth.uid
      ? <Suspense fallback={<Loading />}><Component {...routeProps} /></Suspense>
      : <Redirect to='/login' />
  )} />
)

export default connect((state) => {
    return {
        auth: state.firebase.auth
    };
})(PrivateRoute);