import { STORE_PROFILE, STORE_PROFILE_ERROR } from "../constants"

const initialState = {
    personalCellphone: "+393332986272",
    companyCellphone: "+393332986272",
    address: "via Beccaria, 1, Cormano (MI), Italy",
    personalEmail: "toto.castaldi@gmail.com",
    dateOfBirth: "1976-03-13",
    companyComputer: "AUSU I7 16GB SSD250 NVIDIA",
    iban : "IT30S0300203280854993231897"
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case STORE_PROFILE:
            return action.profile;
        case STORE_PROFILE_ERROR:
            console.error("profile stored error", action.error);
            return state;
        default:
            return state;
    }

}

export default profileReducer;