import React from 'react'
import { toast } from 'react-toastify'

export default ({img, title, commands, url, openCalendarModal}) => {
  const copyCommand = e => {  
    e.preventDefault()
    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute("id", "dummy_id");
    document.getElementById("dummy_id").value=e.currentTarget.getAttribute("data-command");
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);

    toast.info("Command copied", { autoClose: 2000 })
  }


  const openUrl = e => {
    const url = e.currentTarget.getAttribute("data-url")
    if (url)
      window.open(url, "_new")
  }

  return (
    <div key={title} data-url={url} onClick={title === 'Calendars' ? openCalendarModal : openUrl }>
      <img src={require(`../assets/${img}`)} alt={title}/>
      <div>
          <h3>{title}</h3>
          <div className="commands">
            {commands && commands.map(c => (
              <button key={c.text} data-command={c.command} onClick={copyCommand}>
                  {c.text}
              </button>
            ))}
          </div>
      </div>
    </div>
  )
}